import React, {useContext, useEffect, useRef, useState} from 'react';
import './style.css';
import {DistributionContext} from '../../context/device.context';

const DistributionFilter = () => {
  const {assignFilter, filters} = useContext(DistributionContext);
  const [showFilter, setShowFilter] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && buttonRef.current && !buttonRef.current.contains(event.target as Node) && !divRef.current.contains(event.target as Node)) {
        setShowFilter(false);
      }
    }

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [buttonRef]);

  return (
    <div className={'relative'}>
      {(filters.installation || filters.registration) &&
        <div className={'absolute top-1 right-1 w-2 h-2 bg-primary-60 rounded-full'}/>
      }
      <button ref={buttonRef} onClick={() => setShowFilter(!showFilter)} className={'bg-white rounded-md flex items-center justify-center shadow-md w-10 h-10'}>
        <span className={'mtr-icon'}>filter_alt</span>
      </button>
      <div ref={divRef} className={`text-xs absolute w-max bg-white filter transition rounded-md py-2.5 px-3 top-14 right-0 gap-1 shadow-md flex flex-col ${showFilter ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
        <span className={'uppercase text-left text-gray-500 border-b pb-1 border-gray-500 font-semibold mb-1'}>pokaż punkty dystrybucji</span>
        <label className="flex items-center gap-x-2 max-w-max hover:cursor-pointer">
          <input onChange={(e) => assignFilter('installation', e.target.checked)} className="mtr-checkbox-small accent-primary-50" type="checkbox" checked={filters.installation}/>
          <span>Możliwość montażu urządzenia e&#8209;TOLL</span>
        </label>
        <label className="flex items-center gap-x-2 max-w-max hover:cursor-pointer">
          <input onChange={(e) => assignFilter('registration', e.target.checked)} className="mtr-checkbox-small accent-primary-50" type="checkbox" checked={filters.registration}/>
          <span>Możliwość zarejestrowania urządzenia e&#8209;TOLL</span>
        </label>
      </div>
    </div>
  )
}

export default DistributionFilter;
