import React from 'react';
import {DistributionItem} from '../../../pages/gdzie-kupic';
import CustomLink from '../../shared/custom-link/CustomLink';
import HiddenPhoneNumber from '../../hidden-phone-number/HiddenPhoneNumber';
import Tags from './Tags';

const DistributionItemList = ({item}: {item: DistributionItem}) => {
  return (
    <div className={'flex flex-col lg:flex-row gap-5 lg:gap-10 w-full px-5 py-6 lg:px-10 lg:py-16 border-b justify-between'} key={item.id}>
      <div className={'flex flex-col gap-2 text-left'}>
        <p className={'text-xl sm:text-2xl mb-2 lg:mb-5'}>{item.name || item.address}</p>
        <span className={'text-sm sm:text-base'}>
          <span className={'font-bold'}>Adres: </span>
            {item.address}
          </span>
        {item.phone &&
          <span className={'text-sm sm:text-base flex items-center'}>
            <span className={'font-bold'}>Telefon:&nbsp;</span>
            <HiddenPhoneNumber phoneNumber={item.phone}/>
          </span>}
        {item.email &&
          <span className={'text-sm sm:text-base'}>
            <span className={'font-bold'}>Email: </span>
            <CustomLink isInternal={false} to={`mailto:${item.email}`}>{item.email}</CustomLink>
          </span>}
        {(item.installation || item.registration) && <Tags installation={item.installation} registration={item.registration}/>}
      </div>
      <div className={'shrink-0 self-start'}>
        <CustomLink
          isInternal
          to={`/gdzie-kupic/punkt-sprzedazy/${item.slug}/`}
          target={'_blank'}
          className={'mtr-btn sm:mtr-btn-large mtr-btn-outline-primary mb-auto'} type={'button'}>
          <span>SZCZEGÓŁY</span>
        </CustomLink>
      </div>
    </div>
  )
}

export default DistributionItemList;
