import TextFormatter from '../../../utils/TextFormatter';
import {DistributionItem} from '../../../pages/urzadzenie/gdzie-kupic';

export class DistributionItemHelper {
  static slugifyDistributionItems(data: Partial<DistributionItem>[]): Partial<DistributionItem>[] {
    const distributionItems: Record<string, Partial<DistributionItem>> = {};

    data.forEach(node => {
      const slug = TextFormatter.slugify(node.name || node.address);
      const item = distributionItems[`${slug}`];


      const slugRepeated: boolean = !!item;

      if (slugRepeated) {
        item.rep++;

        const rep = item.rep;

        distributionItems[`${slug}-${rep}`] = {
          ...node,
          rep: item.rep,
          slug: `${slug}-${rep}`,
        }
      } else {
        distributionItems[`${slug}`] = {
          ...node,
          rep: 0,
          slug,
        }
      }
    });

    const distributionItemsArray: Partial<DistributionItem>[] = [];

    for (const key of Object.keys(distributionItems)) {
      distributionItemsArray.push(distributionItems[key]);
    }

    return distributionItemsArray;
  }
}
