import React, {useContext, useEffect} from "react";
import {graphql, HeadProps, PageProps} from "gatsby";

import Layout from "../components/Layout";
import MapComponent from "../components/map/Map";
import {MapContext, MapProvider} from "../context/map/map.context";
import {ISpecialOfferSection} from "../components/templates/special-offer/special-offer.interface";
import {IHelpSection} from "../components/templates/help/help-section.interface";
import SpecialOffer from "../components/templates/special-offer/SpecialOffer";
import Help from "../components/templates/help/Help";
import '../styles/page/distribution/distribution.style.scss';
import {SiteMetadata} from "../types/site-metadata.type";
import {SiteMetadataConfigProps} from "../types/metadata-config-props.type";
import DeviceSEO from "../components/device/DeviceSEO";
import {PageContextType} from "../types/page-context.type";
import {ILngLat} from "../context/map/types/marker.interface";
import {MapLayersEnum} from "../context/map/types/maplayers.enum";
import DistributionForm, {DistributionFormState} from '../components/device/DistributionForm';
import {DistributionContext, DistributionProvider} from '../components/device/context/device.context';
import {DistributionItemHelper} from '../components/device/distribution-item/distribution-item.helper';
import DistributionFilter from '../components/device/distribution-item/distribution-filter/DistributionFilter';
import DistributionItemList from '../components/device/distribution-item-list/DistributionItemList';
import {IMAGES} from '../context/map/Images';
import Video, {IVideoSection} from '../components/video/Video';

export type DistributionItem = {
  address: string,
  id: string,
  phone: string,
  name: string,
  email: string,
  coordinates: ILngLat,
  installation?: boolean,
  registration?: boolean,
  slug?: string,
  rep?: number,
  type?: 'partner'|'gas_station',
}

type StrapiDistributionItems = {
  allStrapiDistributionItem: {
    nodes: DistributionItem[]
  }
}

type DistributionProps = {
  strapiDeviceDistribution: {
    title: string,
    inputRangeLabel: string,
    inputPlaceholder: string,
    specialOffer: ISpecialOfferSection,
    help: IHelpSection,
    siteMetadata: SiteMetadata,
    video: IVideoSection,
  }
} & SiteMetadataConfigProps & StrapiDistributionItems


const DistributionWrapper = (props: PageProps<DistributionProps, PageContextType>) => {
  return (
    <DistributionProvider>
      <MapProvider>
        <Distribution {...props} children={undefined}/>
      </MapProvider>
    </DistributionProvider>
  )
}

const Distribution = ({data: {strapiDeviceDistribution, allStrapiDistributionItem}, pageContext, location}: PageProps<DistributionProps, PageContextType>) => {
  const params = new URLSearchParams(location.search);
  const address = params.get('address');
  const range = params.get('range');
  const lng = params.get('lng');
  const lat = params.get('lat');
  const distributionFormState = DistributionFormState.create(address, range, lng, lat);

  const {title, inputPlaceholder, inputRangeLabel, specialOffer, help, siteMetadata, video} = strapiDeviceDistribution;
  const {setDistributionItems, items, filtered, filteredMessage, reset} = useContext(DistributionContext);
  const {cleanCircle} = useContext(MapContext);

  useEffect(() => {
    setDistributionItems(DistributionItemHelper.slugifyDistributionItems(allStrapiDistributionItem.nodes) as DistributionItem[]);
  }, []);

  return (
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={'Gdzie kupić'}>
      <section className={'flex items-center justify-center pt-24 sm:px-10 lg:px-36 py-10 pb-36 relative'}>
        <div className={'flex flex-col text-center items-center z-10 w-full xl:w-auto'}>
          <h1 className={'font-head text-4xl xl:text-5xl mb-12 px-5 lg:px-0'}>{title}</h1>
          <DistributionForm data={distributionFormState}/>
          <div className={'relative w-full'}>
            <MapComponent
              className={'w-full h-[500px] sm:h-[600px] sm:rounded-lg'}
              markers={items}
              images={IMAGES}
              layer={MapLayersEnum.DISTRIBUTION}
            />
            <div className={'absolute top-4 right-4'}>
              <DistributionFilter/>
            </div>
            <div
              className={`h-10 text-sm shadow bg-white rounded-full absolute top-4 items-center flex gap-5 w-fit max-w-[80%]
               py-5 pl-6 right-1/2 translate-x-1/2 transition duration-300 ${!!filteredMessage ? 'opacity-100 visible translate-y-0' : 'opacity-0 invisible translate-y-[-15px]'}`}>
              {filteredMessage}
              <button onClick={() => { cleanCircle(); reset(); }} className={'mtr-icon !text-sm pr-3'}>
                close
              </button>
            </div>
          </div>
          {filtered &&
            <div className={'flex flex-col mt-10 w-full max-h-screen overflow-y-auto'}>
              {items?.map((item) => <DistributionItemList item={item} key={item.id}/>)}
            </div>
          }
        </div>
        <div className={'h-[600px] w-full absolute top-0 left-0 z-0 bg-neutrals-90'}/>
      </section>
      <Video video={video}/>
      <SpecialOffer specialOffer={specialOffer} />
      <Help helpSection={help} />
    </Layout>
  )
}

export default DistributionWrapper;

export const Head = (props: HeadProps<DistributionProps>) => {
  const path = props.location.pathname;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const siteMetadata = props.data.strapiDeviceDistribution.siteMetadata;
  return (
    <DeviceSEO siteUrl={siteUrl} path={path} siteMetadata={siteMetadata} />
  )
}

export const query = graphql`
  query {
    allStrapiDistributionItem(sort: {fields: id}) {
      nodes {
        address
        id
        phone
        email
        coordinates {
          lat
          lng
        }
        name
        installation
        registration
        type
      }
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    strapiDeviceDistribution {
      video {
        description {
          data {
            description
          }
        }
        title
        youtubeUrl
        showSection
      }
      siteMetadata {
        crumbLabel
        title
        description
      }
      title
      inputRangeLabel
      inputPlaceholder
      specialOffer {
        button {
          label
          url
        }
        title {
          data {
            title
          }
        }
        description {
          data {
            description
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      help {
        description
        email
        number
        title
      }
    }
  }
`
