import React, {useContext, useEffect, useState} from 'react';
import {ActionMeta, SingleValue} from 'react-select';
import {InputActionMeta} from 'react-select/dist/declarations/src/types';
import AsyncSelect from 'react-select/async';

import {ILngLat} from '../../context/map/types/marker.interface';
import {useForm} from "react-hook-form";
import {getCustomSelectStyles} from '../../styles/custom-select.styles';
import {MapContext} from '../../context/map/map.context';
import {DistributionContext} from './context/device.context';
import FormError from '../shared/form-error/FormError';

interface Result {
  address: {
    country: string,
    countryCode: string,
    countryCodeISO3: string,
    countrySecondarySubdivision: string,
    countrySubdivision: string,
    freeformAddress: string,
    municipality: string,
    streetName?: string,
  },
  position: {
    lat: number,
    lon: number,
  }
}
export interface IFormState {
  address: string,
  range: number,
  coordinates: ILngLat | null,
}

export class DistributionFormState implements IFormState {
  address = '';
  range = 75;
  coordinates: ILngLat | null = null;

  static create(address: string | null, range: string | null, lng: string | null, lat: string | null): DistributionFormState | null {
    if (address && range && lng && lat) {
      const newDistributionFormState = new DistributionFormState();
      newDistributionFormState.range = Number(range);
      newDistributionFormState.address = address;
      newDistributionFormState.coordinates = {lat: Number(lat), lng: Number(lng)};

      return newDistributionFormState;
    }

    return null;
  }
}

type Option = {value: ILngLat, label: string};

const noOptionMessage = () => <span>Brak wyników</span>
const loader = () => <span>Ładowanie...</span>

const DistributionForm = ({data}: {data: DistributionFormState | null}) => {
  const {renderCircle, cleanCircle, layersInitialized} = useContext(MapContext);
  const distributionContext = useContext(DistributionContext);
  const [inputValue, setInputValue] = useState("");
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [defaultOptions, setDefaultOptions] = useState<Option[] | null>(null);
  const [selectedValue, setSelectedValue] = useState<SingleValue<Option> | null>(null);

  const {register, trigger, setValue, watch, handleSubmit, formState: { errors }} = useForm<IFormState>()

  useEffect(() => {
    if (!data || !layersInitialized || !distributionContext.filterByDistance) {
      return;
    }
    const {address, range, coordinates} = data;

    let validRange = range;
    if (range < 1) validRange = 1;
    if (range > 100) validRange = 100;

    setValue('address', address);
    setValue('range', validRange);
    setValue('coordinates', coordinates);

    setInputValue(address);
    distributionContext.filterByDistance(coordinates as ILngLat, validRange, address)
      .then(itemsAvailable => {
        if (itemsAvailable) renderCircle(coordinates as ILngLat, validRange);
        else cleanCircle();
      });
  }, [layersInitialized])

  const onSubmitForm = handleSubmit((values) => {
    const {address, range, coordinates} = values;
    if (!coordinates || !distributionContext.filterByDistance) return;
    const searchParams = new URLSearchParams();
    searchParams.append('address', address);
    searchParams.append('range', range.toString());
    searchParams.append('lng', coordinates.lng.toString());
    searchParams.append('lat', coordinates.lat.toString());

    history.pushState({}, '',`${window.location.pathname}?${searchParams}`)
    distributionContext.filterByDistance(coordinates, range, address)
      .then((itemsAvailable) => {
        if (itemsAvailable) renderCircle(coordinates, range);
        else cleanCircle();
      });
  });

  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change' || actionMeta.action === 'set-value') {
      setInputValue(newValue);
    }
  }

  const handleSelect = (newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
    if (actionMeta.action === 'clear') {
      setSelectedValue(null);
      setValue('coordinates', null);
    }
    if (!newValue) return;

    setValue('coordinates', newValue.value);
    setValue('address', newValue.label);
    setSelectedValue(newValue);
    const ignore = trigger('coordinates');
  }

  const searchAddress = async (val: string): Promise<Option[]> => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    return new Promise<Option[]>((resolve) => {
      const newTimeoutId = setTimeout(async () => {
        try {
          const url = `https://api.tomtom.com/search/2/geocode/${val}.json?key=${process.env.TOMTOM_TOKEN}&language=pl-PL&countrySet=PL`;
          const res = await fetch(url, {method: 'GET', headers: {'Content-Type': 'application/json'}});
          const data = await res.json();
          const mappedOptions = data.results.map((d: Result) => ({value: {lat: d.position.lat, lng: d.position.lon}, label: d.address.freeformAddress }));
          setDefaultOptions(mappedOptions);
          resolve(mappedOptions);
        } catch (e) {
          console.error(e);
        }
      }, 400);

      setTimeoutId(newTimeoutId);
    })
  }

  const onReset = () => {
    setSelectedValue(null);
    distributionContext.reset();
    setValue('coordinates', null);
    cleanCircle();
  }

  return (
    <form onSubmit={onSubmitForm}
          className={'flex flex-col w-full md:w-fit xl:flex-row gap-x-2.5 gap-y-5 mb-10 items-start px-5 lg:px-0'}>
      <div className={'flex flex-col items-center relative w-full'}>
        <AsyncSelect<Option>
          {...register('coordinates', { required: true })}
          loadingMessage={loader}
          noOptionsMessage={noOptionMessage}
          inputValue={inputValue}
          loadOptions={(v) => searchAddress(v)}
          isClearable
          cacheOptions
          defaultOptions={defaultOptions ? defaultOptions : false}
          onInputChange={handleInputChange}
          isMulti={false}
          value={selectedValue}
          onChange={handleSelect}
          className={'w-full xl:w-[590px] text-left'}
          placeholder={'Wyszukaj i wybierz lokalizację'}
          styles={getCustomSelectStyles<Option>(errors.coordinates?.type === 'required')}
        ></AsyncSelect>
        {errors.coordinates?.type === 'required' && <FormError message={'Wybierz lokalizację'}/>}
      </div>
      <div className={'flex flex-col md:flex-row gap-2.5 w-full'}>
        <div className={'flex flex-col gap-2.5 md:w-[360px] px-1 mb-2 md:mb-0'}>
          <label className={'text-sm text-black mr-auto mb-px'}>
            {'Promień wyszukiwania (km)'}&nbsp;
            <span className={'font-bold'}>{watch('range')}</span>
          </label>
          <input
            className={'slider appearance-none h-1.5 rounded-full bg-primary-60'}
            {...register('range', { required: true, min: 1, max: 100})}
            type="range"
            id="range"
            min="1"
            max="100"
            name="range"
            step="1"/>
        </div>
        <div className={'flex gap-2 ml-auto'}>
          <button onClick={() => onReset()} type={'button'} className={'mtr-btn-large mtr-btn-outline-secondary'}>
            <span>RESETUJ</span>
          </button>
          <button type={'submit'}
                  className={'mtr-btn-large mtr-btn-[secondary]'}>
            <span>SZUKAJ</span>
          </button>
        </div>
      </div>
    </form>
  )
}

export default DistributionForm;
